<template>
  <div>
    <info></info>
    <div class="flix-form-group">
      <calendarBreadcrumbs :link="'dashboardWebhook'" :linkAll="'dashboardWebhooks'" />
    </div>
    <div>
      <div :key="$route.params.id">
        <div class="flix-form-group" v-for="(hook, index) in hooks" :key="index">
        <div class="flix-row">
          <div class="flix-col flix-col-md-12">
            <b>{{ index + 1 }}. {{ $tc('message.webhooks', 1) }}</b>
          </div>
            <div class="flix-col flix-col-md-3">
              <select class="flix-form-control" v-model="hooks[index].type" @change="checkHook">
                  <option value="newBooking">{{ $t('message.add', { name: $tc('message.appointments', 1)}) }}</option>
                  <optgroup>
                    <option value="deleteHook">- {{ $t('message.delete') }} -</option>
                  </optgroup>
              </select>
              Triggers <b>POST</b>-Request ...
            </div>
            <div class="flix-col flix-col-md-9">
                <input type="text" class="flix-form-control" placeholder="https://" v-model="hooks[index].url" @change="checkHook">
                ... an diese URL <flix-icon id="cloud-upload"></flix-icon> <a href="#" class="flix-btn flix-btn-xs flix-btn-default" @click.prevent="runTest(index)">Test</a>
            </div>
        </div>
      </div>
      <div class="flix-form-group flix-text-right">
        <addBtn :onClick="addHook">
          <template v-slot:text>
            {{ $t('message.add', {name: ''}) }}
          </template>
        </addBtn>
      </div>
        <div class="flix-form-group">
          <saveBtn :onClick="saveHook">
            <template v-slot:text>
              {{ $t('message.save', {name: ''}) }}
            </template>
          </saveBtn>
        </div>
        <div v-if="saved" class="flix-form-group flix-text-right">
         <div class="flix-alert flix-alert-success">
          {{ $t('message.successfullSaved') }}
         </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    info () { return import('./info') },
    calendarBreadcrumbs () { return import('@/components/assets/calendarBreadcrumbs') }
  },
  data () {
    return {
      hooks: [],
      saved: false
    }
  },
  methods: {
    getHooks () {
      this.$flix_post({
        url: 'hooks/get',
        data: {
          user: this.$getUserVariables().user.md5_id,
          ID: this.$route.params.id || '*'
        },
        callback: this.setHooks.bind(this)
      })
    },
    setHooks (data) {
      if (data.data[0] && data.data[1]) {
        this.hooks = data.data[1]
      }
    },
    saveHook () {
      this.$flix_post({
        url: 'hooks/set',
        data: {
          user: this.$getUserVariables().user.md5_id,
          hooks: this.hooks,
          ID: this.$route.params.id || '*'
        },
        callback: function (data) { this.saved = true; setTimeout(function () { this.saved = false }.bind(this), 2000) }.bind(this)
      })
    },
    addHook () {
      this.hooks.push({ type: 'newBooking', url: '' })
    },
    checkHook () {
      const hooks = this.hooks
      hooks.forEach(function (hook, index) {
        if (hook.type === 'deleteHook') {
          hooks.splice(index, 1)
        }
      })
      this.hooks = hooks
    },
    async runTest (index) {
      const data = this.getHookData(this.hooks[index].type)
      const res = await fetch(this.hooks[index].url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      if (res.status === 200) {
        alert('success')
      }
    },
    getHookData (type) {
      const begin = new Date()
      const end = new Date()
      begin.setHours(begin.getHours() + 1)
      end.setHours(end.getHours() + 2)

      let data = {}
      switch (type) {
        case 'newBooking':
          data = {
            title: 'Demo-Calendar',
            begin: begin.getFullYear() + '-' + (begin.getMonth() + 1) + '-' + begin.getDate() + ' ' + begin.getHours() + ':00:00',
            end: end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate() + ' ' + end.getHours() + ':00:00',
            userEmail: 'demobooking@bookingflix.com',
            bookingID: '123-456',
            data: JSON.stringify({
              Vorname: 'Max',
              Nachname: 'Mustermann',
              Email: 'demobooking@bookingflix.com'
            }),
            service: ''
          }
          break
      }
      return data
    }
  },
  mounted () {
    this.getHooks()
  }
}
</script>
<style lang="sass">
</style>
